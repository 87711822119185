import applyButton from "@/lib/data-service/haolv-default/consumer_menu_applyButton";
import myApplyList from "@/lib/data-service/haolv-default/consumer_apply_myApplyList";
import repealApply from "@/lib/data-service/haolv-default/consumer_apply_repealApply";
import consumer_apply_workflowMyApplyList from '@/lib/data-service/haolv-default/consumer_apply_workflowMyApplyList';
import moment from "moment"
import RevokeModal from "@/page/admin/office/my-apply/components/process/RevokeModal.vue";
export default {
  name: "Apply",
  data() {
    return {
      tableLoad: false,
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: '1',
          label: "出差申请",
        },
        {
          value: '2',
          label: "超标申请",
        },
        {
          value: '7',
          label: "预订申请",
        },
        {
          value: '4',
          label: "改签申请",
        },
        {
          value: '5',
          label: "退订申请",
        },
      ],
      formData: {
        pageSize: 9,
        currentPage: 1,
        date: [],
        approveType: "",
        totalPage: 0,
        selectType: 1,
        searchValue: '',
      },
      jurisdiction: false,   // 按钮权限范围
      tableData: [],

      modalConfig: {
        revokeVisible: false,
      },
      currentProcessInfo: {},
    };
  },
  methods: {
    _success() {
      this.getList();
    },
    getTimeDay(startDate,endDate){
      if (startDate != null && endDate != null) {
        return moment(endDate).diff(moment(startDate), 'days') + 1;
        // return (new Date(endDate)-new Date(startDate))/(1000*60*60*24)
      } else {
        return '--';
      }
    },
    goInfo(val) {
      // appType: 1.我的申请 2待我审批
      if (val.approveType === 2) {
        this.$router.push({
          name: "admin-my-apply-manage-apply-approve-exceed",
          query: { applyId: val.id, applyNo: val.applyNo, appType: 1, selectType: this.formData.selectType, approveStatus: val.approveStatus, type: "applyDetail", lastMenu: 'adminMyApply' }
        })
        return
      }
      this.$router.push({
        name: "admin-my-apply-manage-apply-info",
        query: { applyId: val.id, applyNo: val.applyNo, appType: 1, selectType: this.formData.selectType, approveStatus: val.approveStatus, type: "info", approveType: val.approveType, lastMenu: 'adminMyApply' },
      })
    },
    apply() {
      // this.$router.push({ name: "admin-my-apply-manage-apply-form" });
      this.$router.push({ name: "admin-add-evection" });
    },
    expense() {
      this.$router.push({ name: "admin-my-apply-manage-expense-apply" });
    },
    revocation(val) {
      console.log(val);
      if(this.$store.state.workflow === '1') {
        // 新审批流
        this.currentProcessInfo = {
          processInstanceId: val.applyNo,
          taskId: val.taskId,
          signFlag: null,
          currentUserInfo: {
            id: this.$store.state.userInfo.id,
          },
        }
        this.modalConfig.revokeVisible = true;
      } else {
        // 旧审批流
        this.$confirm("确定要撤销吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              repealApply({ applyNo: val.applyNo }).then((res) => {
                this.$message({ type: "success", message: "撤销成功！" });
                this.getList();
              });
            })
            .catch(() => {});
      }

    },
    edit() {
      this.$router.push({ name: "admin-my-apply-manage-apply-form" });
    },
    handleCurrentChange(currPage) {
      this.formData.currentPage = currPage;
      this.getList();
    },
    // 出差申请按钮状态
    async getApplyButton() {
      try {
        const res = await applyButton()
        this.jurisdiction = res.datas.jurisdiction
      } catch (error) {

      }
    },
    getList() {
      this.tableLoad = true;
      let data = Object.assign({}, this.formData);
      data.date && data.date.length > 0
        ? ((data.startDateTime = data.date[0]),
          (data.endDateTime = data.date[1]))
        : "";
      delete data.totalPage;
      let applyApi = this.$store.state.workflow === '1' ? consumer_apply_workflowMyApplyList(data) : myApplyList(data);
      applyApi.then((res) => {
        let list = res.datas.list;
        list.forEach((item) => {
          if (item.recordList && item.recordList.length) {
            const record = item.recordList[0];
            item.lastNodeApprover = record.approverUserName;
            item.lastNodeTime = moment(record.gmtCreate).format(
              "MM-DD hh:mm"
            );
            switch (record.approveStatus) {
              case 0:
                item.lastNodeStatus = "未开始";
                break;
              case 1:
                item.lastNodeStatus = "待审批";
                break;
              case 2:
                item.lastNodeStatus = "已审批通过";
                break;
              case 3:
                item.lastNodeStatus = "已审批拒绝";
                break;
              case 4:
                item.lastNodeStatus = "已失效";
                break;
            }
          } else {
            item.lastNodeApprover = `发起人：${item.applyName}`;
            item.lastNodeStatus = "";
            item.lastNodeTime = "";
          }
        });
        this.tableData = list;
        this.formData.totalPage = res.datas.totalCount;
        this.tableLoad = false;
      });
    },
  },
  created() {

  },
  async activated() {
    this.getApplyButton();
    await this.$store.state.workflowDefer.promise;
    // await this.$store.state.userInfoDefer.promise;
    this.getList();

  },
  watch: {},
  computed: {},
  components: {RevokeModal}
};
